/*eslint-disable */
import request from '../../Utils/curl'
import Utility from "../../Utils/utility"

let UniversityCourses = {
  /**
   * organisationCourseList
   */
  async organisationCourseList (context, payload) {
    Utility.showLoader = true;
    Utility.loadingMsg = "Please wait...."; 
    try{
      let post_data = new FormData();
  
      for (let key in payload) {
        if (payload[key]) {
          post_data.append(key, payload[key])
        }
      }

      return await request.curl(context, "organisation_course_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at organisationCourseList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * organisationCourseView
   */
  async organisationCourseView (context, organisationCourseId) {
    try {      
      let post_data = new FormData();
      post_data.append('cour_id', organisationCourseId);
      return await request.curl(context, "organisation_course_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at organisationCourseView() and Exception:",err.message)
    }    
  },

  /**
   * organisationCourseAdd
   */
  async organisationCourseAdd (context, organisationCourseObj) {
    try{
      let post_data = new FormData();
    
      for (let key in organisationCourseObj) {
        post_data.append(key, organisationCourseObj[key]);
      }

      return await request.curl(context, "organisation_course_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at organisationCourseAdd() and Exception:',err.message)
    }
  },

  /**
   * organisationCourseEdit
   */
  async organisationCourseEdit (context, organisationCourseObj) {
    try{
      let post_data = new FormData();
    
      for (let key in organisationCourseObj) {
        post_data.append(key, organisationCourseObj[key]);
      }

      return await request.curl(context, "organisation_course_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at organisationCourseEdit() and Exception:',err.message)
    }
  },

  /**
   * organisationCourseDelete
   */
  async organisationCourseDelete (context, organisationCourseId) {
    try{
      let post_data = new FormData();
      
      post_data.append('cour_id', organisationCourseId);

      return await request.curl(context, "organisation_course_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at organisationCourseDelete() and Exception:',err.message)
    }
  }
}

export {
  UniversityCourses
}
